// App.vue
<template>
  <div id="app" class="relative h-screen w-full overflow-hidden" :key="track">
    <!-- Find more filters here : https://tailwindcss.com/docs/invert -->
    <div class="absolute inset-0 w-full h-full overflow-hidden invert">
      <div
        id="youtube-player"
        class="absolute top-1/2 left-1/2 w-[200%] h-[200%] -translate-x-1/2 -translate-y-1/2"
      ></div>
    </div>
    <!-- Blurred grid overlay -->
    <div class="absolute inset-0 backdrop-blur-sm bg-black bg-opacity-30">
      <div class="w-full h-full" :style="gridStyle"></div>
    </div>
    <!-- Foreground content -->
    <div
      class="absolute inset-0 flex flex-col justify-between p-4 sm:p-8 text-white"
    >
      <!-- Title and Artist -->
      <div
        class="flex-grow flex flex-col justify-center items-center text-center"
      >
        <transition name="fade" mode="out-in">
          <h1
            :key="track.title"
            class="text-4xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl font-bold mb-4 leading-tight"
          >
            {{ track.title }}
          </h1>
        </transition>
        <transition name="fade" mode="out-in">
          <h2
            :key="track.artist"
            class="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl mb-8"
          >
            {{ track.artist }}
          </h2>
        </transition>
      </div>
      <!-- Controls -->
      <div
        class="flex justify-center items-center space-x-4 sm:space-x-6"
        :key="track.id"
      >
        <button
          @click="playPause"
          class="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-colors"
        >
          <font-awesome-icon
            :icon="isPlaying ? ['fas', 'pause'] : ['fas', 'play']"
            size="lg"
          />
        </button>
        <button
          @click="skipTrack"
          class="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-colors"
        >
          <font-awesome-icon :icon="['fas', 'forward']" size="lg" />
        </button>
        <button
          @click="toggleMute"
          class="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-colors"
        >
          <font-awesome-icon
            :icon="isMuted ? ['fas', 'volume-mute'] : ['fas', 'volume-up']"
            size="lg"
          />
        </button>
        <button
          @click="vote(track.id, 'upvote')"
          :disabled="hasVoted(track.id)"
          class="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-colors"
        >
          <font-awesome-icon :icon="['fas', 'thumbs-up']" size="lg" />
        </button>
        <button
          @click="vote(track.id, 'downvote')"
          :disabled="hasVoted(track.id)"
          class="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-colors"
        >
          <font-awesome-icon :icon="['fas', 'thumbs-down']" size="lg" />
        </button>
        <a href="#" @click.prevent="showAbout">About</a>
      </div>
    </div>
  </div>
  <!-- About Modal -->
  <div v-if="isAboutVisible" class="modal">
    <div class="modal-content">
      <h2>About This Project</h2>
      <p>
        This is a YouTube Radio Player that plays audio from YouTube videos.
      </p>
      <button @click="hideAbout">Close</button>
    </div>
  </div>
</template>

<script>
/**
 * @typedef {Object} YT
 * @property {function} Player
 */

/** @type {YT} */
const YT = window.YT;
const apiUrlPath = process.env.VUE_APP_API_URL;
console.log("API Path:", apiUrlPath);

export default {
  data() {
    return {
      isPlaying: false,
      isAboutVisible: false,
      player: null,
      playlist: [],
      track: {},
      currentTrackIndex: 0,
      online: navigator.onLine,
      youtubeApiReady: false,
      gridStyle: {
        backgroundImage: `repeating-linear-gradient(0deg, transparent, transparent 1px, rgba(255,255,255,0.1) 1px, rgba(255,255,255,0.1) 2px),
                          repeating-linear-gradient(90deg, transparent, transparent 1px, rgba(255,255,255,0.1) 1px, rgba(255,255,255,0.1) 2px)`,
        backgroundSize: "20px 20px",
      },
    };
  },
  mounted() {
    //this.initializeYouTubeAPI();
    this.initializeApplication();
  },
  beforeUnmount() {
    // Clean up
    if (this.player) {
      this.player.destroy();
    }
    window.onYouTubeIframeAPIReady = null;
  },

  methods: {
    async initializeApplication() {
      this.loading = true;
      this.error = null;
      try {
        await this.initializeYouTubeAPI();
        await this.fetchPlaylist();
        this.createYouTubePlayer();
      } catch (error) {
        console.error("Initialization error:", error);
        this.error = "Failed to initialize the application. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    initializeYouTubeAPI() {
      return new Promise((resolve, reject) => {
        if (window.YT && window.YT.Player) {
          this.youtubeApiReady = true;
          resolve();
        } else {
          window.onYouTubeIframeAPIReady = () => {
            this.youtubeApiReady = true;
            resolve();
          };
          const tag = document.createElement("script");
          tag.src = "https://www.youtube.com/iframe_api";
          tag.onerror = reject;
          const firstScriptTag = document.getElementsByTagName("script")[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
      });
    },
    createYouTubePlayer() {
      if (!this.youtubeApiReady || !window.YT || !window.YT.Player) {
        console.error("YouTube API is not ready");
        return;
      }
      this.player = new window.YT.Player("youtube-player", {
        width: "600px",
        height: "400px",
        videoId: "",
        // cf. https://developers.google.com/youtube/player_parameters?hl=fr
        playerVars: {
          autoplay: 0,
          controls: 0,
          disablekb: 1,
          fs: 0,
          rel: 0,
          showinfo: 0,
          modestbranding: 1,
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
        },
      });
    },
    setPlayerSize() {
      const playerElement = document.getElementById("youtube-player");
      if (playerElement) {
        const aspectRatio = 16 / 9; // Assuming 16:9 aspect ratio for the video
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const windowRatio = windowWidth / windowHeight;

        if (windowRatio > aspectRatio) {
          playerElement.style.width = "200%";
          playerElement.style.height = "200%";
        } else {
          const newWidth = windowHeight * aspectRatio * 2 + "px";
          playerElement.style.width = newWidth;
          playerElement.style.height = "200%";
        }
      }
    },
    onPlayerReady() {
      this.player.mute();
      this.setPlayerSize();
      if (this.playlist.length > 0) {
        this.loadTrack(this.currentTrackIndex);
      }
    },
    onPlayerStateChange(event) {
      if (event.data == window.YT.PlayerState.ENDED) {
        this.skipTrack();
      }
    },
    fetchPlaylist() {
      fetch(apiUrlPath + "/fetch_playlist.php")
        .then((response) => response.json())
        .then((data) => {
          this.playlist = data;
          if (
            this.player &&
            this.youtubeApiReady &&
            this.player.getPlayerState() !== YT.PlayerState.PLAYING
          ) {
            this.loadTrack(this.currentTrackIndex);
          }
        })
        .catch((error) => {
          console.error("Error fetching playlist:", error);
        });
    },
    loadTrack(index) {
      if (
        this.online &&
        this.youtubeApiReady &&
        index >= 0 &&
        index < this.playlist.length
      ) {
        this.player.loadVideoById({
          videoId: this.playlist[index].video_id,
          startSeconds: 1,
          endSeconds: 0,
          suggestedQuality: "large",
        });
        this.track.id = this.playlist[index].id;
        this.track.artist = this.playlist[index].artist;
        this.track.title = this.playlist[index].title;

        this.player.playVideo();
        this.isPlaying = true;
      }
    },
    async vote(trackId, voteType) {
      const fingerprint = Date.now();
      const response = await fetch(`${apiUrlPath}/vote.php?_=${fingerprint}`, {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ trackId, voteType, _: fingerprint }),
      });
      const result = await response.json();
      if (result.success) {
        //this.userVotes[trackId] = voteType;
        //await this.fetchPlaylist(); // Refresh the playlist
      } else {
        alert(result.error);
      }
    },
    hasVoted(trackId) {
      console.log(trackId);
      return false;
    },
    playPause() {
      if (this.online && this.youtubeApiReady && this.player) {
        if (this.isPlaying) {
          this.player.pauseVideo();
        } else {
          this.player.playVideo();
        }
        this.isPlaying = !this.isPlaying;
      }
    },
    skipTrack() {
      if (this.online && this.youtubeApiReady && this.player) {
        this.currentTrackIndex =
          (this.currentTrackIndex + 1) % this.playlist.length;
        this.loadTrack(this.currentTrackIndex);
      }
    },
    toggleMute() {
      this.isMuted = !this.isMuted;
      if (this.isMuted) {
        this.player.mute();
      } else {
        this.player.unMute();
      }
    },
    showAbout() {
      this.isAboutVisible = true;
    },
    hideAbout() {
      this.isAboutVisible = false;
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
#app {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  font: normal 1rem/1 system-ui;
  height: 100vh;
  justify-content: space-evenly;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
